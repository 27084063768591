import React from "react";
import { Col } from "antd";
import { Link } from "react-router-dom";

const Loginbanner = () => {
  return (
    <Col xs={24} sm={12} md={12} lg={12} xl={12} className="lgbg d-sm-none">
      {/* <img src="../img/loginbg.png" alt="bg"/> */}
      <div className="mdle h50 tcent ">
        <div className="txfm w-80">
          <h3>Moving your Products Across Borders</h3>
          <p>
            Had some logistics hassle in the past. Worry no more, With our fast
            and fully automated system, you are guaranted a reliable system
          </p>
          <div className="py1 mobile-talks">
            <Link to="jj" className=" hover transf">
              <img src={`${process.env.REACT_APP_IMAGE}/Badge+iOS.png`} alt="" />
            </Link>
            <Link to="kk" className=" hover transf">
              <img src={`${process.env.REACT_APP_IMAGE}/Badge+Android.png`} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default Loginbanner;
