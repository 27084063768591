import React from "react";
import "./App.less";
import { BrowserRouter as Router } from "react-router-dom";
import UserApp from "./module/Dashboardlayout";
import { Provider } from "react-redux";
import configureStore from "./common/store/store";
import PrivateRoute from "./common/privateRoute";
import { Route } from "react-router-dom";
import Login from "./module/authentication/login"
import Register from "./module/authentication/register"
import { Helmet } from 'react-helmet'
const store = configureStore();
function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Helmet>
                <link rel="icon" href={`${process.env.REACT_APP_IMAGE}/logo192.png`} />
            </Helmet>
          <Route exact path="/login" component={Login} />
          <PrivateRoute path = "/user" component={UserApp} />
          <Route exact path="/register" component={Register} />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
